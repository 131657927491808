<template>
  <div class="yt-main" style="padding-top: 0">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>专业管理</el-breadcrumb-item>
        <el-breadcrumb-item>专业匹配</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div
      class="yt-container yt-container-flex"
      :class="{
        'is-pack-up': isPackUp
      }"
      style="height: 100%"
    >
      <div class="yt-content-tree">
        <template v-if="isPackUp">
          <YTIcon class="pack-up-button" :href="'#icon-zhankai'" @click="isPackUp = false" />
        </template>
        <template v-else>
          <div class="header">
            <YTIcon class="pack-up-button" :href="'#icon-shouqi1'" @click="isPackUp = true" />
            <YTIcon style="margin: 0 5px 0 19px; font-size: 20px" :href="'#icon-fenlei1'" />
            <p>专业模型</p>
          </div>
          <div class="tree has-tool">
            <el-tree ref="post-tree" :data="postData" :props="postProps" node-key="id" :highlight-current="true" @node-click="handleNodeClick">
              <div slot-scope="{ node, data }" class="tree-node">
                <el-tooltip effect="dark" :content="node.label" :disabled="node.label.length < 10" :open-delay="1000" placement="top-start">
                  <div class="el-tree-node__label">{{ node.label }}</div>
                </el-tooltip>
              </div>
            </el-tree>
          </div>
        </template>
      </div>
      <div class="yt-content">
        <div class="yt-header-toolbar" style="justify-content: flex-end; margin-right: 11px">
          <el-button type="primary" size="small" @click="seeDetail" style="width: 100px" v-if="matchList.length !== 0">
            <div>
              <YTIcon href="#icon-xiangqing" />
              <p>展示详情</p>
            </div>
          </el-button>
          <el-button @click="toConfig" type="primary" size="small" style="width: 150px">
            <div>
              <YTIcon href="#icon-peizhi" />
              <p>配置专业匹配模型</p>
            </div>
          </el-button>
        </div>
        <template v-if="matchList.length === 0 && !matchLoading">
          <div class="no-hint">
            <YTIcon style="font-size: 117px" :href="'#icon-zanwushuju'" />
            <p>当前暂未匹配专业模型技能与部门的关系</p>
            <p style="margin-bottom: 32px">请点击<a style="border-bottom: 1px solid #448BFF" @click="toConfig">配置专业模型</a>进行配置</p>
          </div>
        </template>
        <template v-else>
          <el-table ref="table" :data="matchList" v-loading="matchLoading" element-loading-text="加载中" height="calc(100% - 80px)" class="yt-table">
            <el-table-column label="部门" prop="departmentName" show-overflow-tooltip />
            <el-table-column label="配置日期" prop="createdTime" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ $formatTime(scope.row.createdTime, 'yyyy-MM-dd hh:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column label="配置人员" prop="creatorName" show-overflow-tooltip />
            <el-table-column label="操作" width="120px">
              <template slot-scope="scope">
                <span class="tool-button" @click="toUpdate(scope.row)">编辑</span>
                <span class="tool-button" @click="toDelete(scope.row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
          <Page
            class="yt-page"
            :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
            :total="total"
            :current="pageNum + 1"
            :page-size-opts="[10, 20, 40, 100]"
            :page-size="pageSize"
            @on-change="changePage"
            @on-page-size-change="changePageSize"
            show-elevator
            show-sizer
            show-total
          />
        </template>
      </div>
    </div>
    <PostMatchConfigDialog ref="configDialog" @refresh="getPostMatch"></PostMatchConfigDialog>
    <ConfirmDialog ref="postSkillDeleteDialog" :title="'删除部门'" @on-ok="deleteMatch">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        是否确认删除该部门岗位匹配数据，删除后将不可恢复
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import PostMatchConfigDialog from '@components/common/dialog/PostMatchConfigDialog'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import postApi from '@/api/post'
import postDepartmentMatchApi from '@api/postDepartmentMatch'

export default {
  name: 'PostMatch',
  components: { YTIcon, PostMatchConfigDialog, ConfirmDialog },
  data() {
    return {
      isPackUp: false,
      postData: [], //岗位数据
      postProps: {
        label: 'name'
      },
      total: 0,
      pageNum: 0,
      pageSize: 10,
      activePost: {},
      matchList: [], //岗位匹配数据
      matchLoading: true
    }
  },
  mounted() {
    this.getPost()
  },
  methods: {
    getPost() {
      postApi.getPostByType(1).then(res => {
        this.postData = res.res
        if (this.postData.length > 0) {
          this.activePost = this.postData[0]
          this.setCurrentKey(this.postData[0].id)
          this.getPostMatch()
        }
      })
    },
    getPostMatch() {
      this.matchLoading = true
      postDepartmentMatchApi
        .getConfigListByPostId(this.activePost.id, this.pageNum, this.pageSize)
        .then(res => {
          this.matchList = res.res.data
          this.total = res.res.total
        })
        .finally(() => {
          this.matchLoading = false
        })
    },
    reGetPostMatch() {
      this.pageNum = 0
      this.getPostMatch()
    },
    seeDetail() {
      this.$router.push({
        path: '/manage/post/match/detail',
        name: 'postMatchDetail',
        params: { postId: this.activePost.id, postName: this.activePost.name }
      })
    },
    handleNodeClick(data) {
      this.activePost = data
      this.reGetPostMatch()
    },
    setCurrentKey(postId) {
      this.$nextTick(() => {
        this.$refs['post-tree'].setCurrentKey(postId ? postId : 0)
      })
    },
    changePage(page) {
      this.pageNum = page - 1
      this.getPostMatch()
    },
    changePageSize(size) {
      this.pageSize = size
      this.getPostMatch()
    },
    toConfig() {
      this.$refs.configDialog.open({ postId: this.activePost.id }, true)
    },
    toUpdate(row) {
      this.$refs.configDialog.open({ postId: this.activePost.id, departmentId: row.departmentId }, false)
    },
    toDelete(data) {
      this.deleteSkillData = data
      this.$refs.postSkillDeleteDialog.open()
    },
    deleteMatch() {
      let payload = {
        postId: this.activePost.id,
        departmentId: this.deleteSkillData.departmentId
      }
      postDepartmentMatchApi.deleteMatch(payload).then(res => {
        if (res.res === true) {
          this.$message.success('删除部门配置成功')
          this.getPostMatch()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.yt-content {
  padding: 12px 20px 16px 20px;
}
.yt-table {
  margin-top: 12px;
}
.no-hint {
  flex-direction: column;
  font-size: 16px;
  height: calc(100% - 32px);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
